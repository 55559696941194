<template>
  <b-overlay
    :show="$store.state.app.isContentLoading"
    spinner-variant="primary"
    spinner-type="grow"
    rounded="sm"
  >

    <b-card
      :title="$t('Sale')"
    >

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          @submit.prevent="handleSubmit()"
          @reset.prevent="resetForm"
        >

          <!-- Header -->
          <b-row>

            <!-- Field: Date -->
            <b-col
              cols="12"
              md="3"
            >
              <validation-provider
                #default="validationContext"
                name="sale-date"
                rules="required"
              >
                <b-form-group
                  :label="$t('Date')"
                  label-for="sale-date"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="CalendarIcon" />
                    </b-input-group-prepend>
                    <flat-pickr
                      id="sale-date"
                      v-model="saleData.sale_date"
                      class="form-control invoice-edit-input"
                      :config="{ dateFormat: 'Y-m-d', minDate: today, maxDate: maxDate }"
                      :state="getValidationState(validationContext)"
                      trim
                      placeholder=""
                    />
                  </b-input-group>

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Client -->
            <b-col
              cols="12"
              md="3"
            >

              <validation-provider
                #default="validationContext"
                name="client"
                rules="required"
              >
                <b-form-group
                  :label="$t('Client')"
                  label-for="client"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="saleData.client_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="clientOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="client"
                  />

                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

            </b-col>

            <!-- Field: Sale Region -->
            <b-col
              cols="12"
              md="3"
            >

              <validation-provider
                #default="validationContext"
                name="sale-region"
                rules="required"
              >
                <b-form-group
                  :label="$t('Sale Region')"
                  label-for="sale-region"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="saleData.sale_region_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="saleRegionOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="sale-region"
                  />

                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

            </b-col>

            <!-- Field: Status -->
            <b-col
              cols="12"
              md="3"
            >

              <validation-provider
                #default="validationContext"
                name="status"
                rules="required"
              >
                <b-form-group
                  :label="$t('Status')"
                  label-for="status"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="saleData.status"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="statusOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="status"
                    class="plain"
                    disabled
                  />

                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

            </b-col>

            <!-- Field: Total Amount -->
            <b-col
              cols="12"
              md="3"
            >
              <validation-provider
                #default="validationContext"
                name="total-amount"
                rules="required"
              >
                <b-form-group
                  :label="$t('Total Amount')"
                  label-for="total-amount"
                >
                  <b-form-input
                    id="total-amount"
                    v-model="saleData.total_amount"
                    class="plain border-light"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder=""
                    readonly
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Discount -->
            <b-col
              cols="12"
              md="3"
            >
              <validation-provider
                #default="validationContext"
                name="discount"
                rules="required"
              >
                <b-form-group
                  :label="$t('Discount')"
                  label-for="discount"
                >
                  <b-form-input
                    id="discount"
                    v-model="saleData.discount"
                    :state="getValidationState(validationContext)"
                    type="number"
                    trim
                    placeholder=""
                    @focus="$event.target.select()"
                    @input="calTotalAmount()"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Net Amount -->
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                :label="$t('Net')"
                label-for="net-amount"
                label-class="font-weight-bold"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="net-amount"
                    v-model="saleData.net_amount"
                    trim
                    placeholder=""
                    class="plain font-weight-bold border-light"
                    readonly
                  />
                  <b-input-group-append>
                    <b-button
                      ref="paybutton"
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      :disabled="isDisabled"
                      variant="outline-primary"
                      @click="payNow()"
                    >
                      <feather-icon
                        icon="CheckCircleIcon"
                      />
                      &nbsp;{{ $t('Pay') }}
                    </b-button>
                  </b-input-group-append>
                </b-input-group>

              </b-form-group>
            </b-col>

            <!-- Field: Receipt -->
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                :label="$t('Cash Receipt')"
                label-for="total-receipt"
                label-class="font-weight-bold"
              >
                <b-form-input
                  id="total-receipt"
                  v-model="saleData.total_receipt"
                  trim
                  placeholder=""
                  class="plain font-weight-bold border-light"
                  readonly
                />

              </b-form-group>
            </b-col>

          </b-row>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <b-tabs
            active-nav-item-class="font-weight-bold text-uppercase text-dark ml-0"
          >
            <b-tab
              active
              @click="initTrHeight"
            >
              <template #title>
                <span>{{ $t('Item') }}</span>
              </template>

              <section
                class="invoice-preview-wrapper"
              >
                <b-row class="mb-1">
                  <!-- Field: Item -->
                  <b-col
                    cols="12"
                    md="8"
                  >
                    <b-form-group
                      :label="$t('Select Item')"
                      label-for="item"
                    >
                      <v-select
                        v-model="itemId"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="itemOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        :searchable="true"
                        input-id="item"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Field: Quantity -->
                  <b-col
                    md="4"
                  >
                    <b-form-group
                      :label="$t('Quantity')"
                      label-for="quantity"
                    >
                      <b-input-group>
                        <b-form-input
                          id="quantity"
                          ref="quantity"
                          v-model="itemQuantity"
                          type="number"
                          trim
                          placeholder=""
                          @keyup.enter="quantityEnter"
                        />
                        <b-input-group-append>
                          <b-button
                            variant="outline-primary"
                            @click="addNewItem"
                          >
                            <feather-icon
                              icon="PlusIcon"
                              class="mr-50"
                            />{{ $t('Add') }}
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>

                    </b-form-group>

                  </b-col>
                </b-row>

                <div
                  ref="formitem"
                  class="repeater-form"
                  :style="{height: trHeight}"
                >
                  <b-row
                    v-for="(item, index) in saleData.sale_items"
                    :key="index"
                    ref="rowitem"
                    class="pb-2"
                  >

                    <!-- Item Form -->
                    <!-- ? This will be in loop => So consider below markup for single item -->
                    <b-col cols="12">

                      <!-- Form Input Fields OR content inside bordered area  -->
                      <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                      <div class="d-flex border rounded">

                        <b-row class="flex-grow-1 p-1">

                          <!-- Item Name -->
                          <b-col
                            cols="12"
                            lg="5"
                          >
                            <label class="d-inline">{{ $t('Item') }}</label>
                            <p class="mb-1">
                              {{ item.item_id }} - {{ item.item_name }}
                            </p>
                          </b-col>

                          <!-- Price -->
                          <b-col
                            cols="6"
                            lg="3"
                            sm="4"
                          >
                            <label class="d-inline">{{ $t('Price') }}</label>
                            <b-form-input
                              v-model="item.price"
                              type="number"
                              class="mb-1"
                              disabled
                              @input="calItemAmount(item.item_id, item.price, item.quantity)"
                            />
                          </b-col>

                          <!-- Quantity -->
                          <b-col
                            cols="6"
                            lg="2"
                            sm="4"
                          >
                            <label class="d-inline">{{ $t('Quantity') }}</label>
                            <b-form-input
                              v-model="item.quantity"
                              type="number"
                              class="mb-1"
                              @input="calItemAmount(item.item_id, item.price, item.quantity)"
                            />
                          </b-col>

                          <!-- Item Amount -->
                          <b-col
                            cols="12"
                            lg="2"
                            sm="4"
                          >
                            <label class="d-inline">{{ $t('Amount') }}</label>
                            <b-form-input
                              v-model="item.amount"
                              type="text"
                              class="mb-1 plain border-light"
                              readonly
                            />
                          </b-col>
                        </b-row>

                        <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                          <feather-icon
                            size="16"
                            icon="XIcon"
                            class="cursor-pointer"
                            @click="removeItem(index)"
                          />

                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>

              </section>

            </b-tab>

            <b-tab @click="initTrHeightReceipt">
              <template #title>
                <span>{{ $t('Cash Receipt') }}</span>
              </template>
              <section class="invoice-preview-wrapper">

                <section
                  class="cash-receipt-wrapper"
                >
                  <b-row class="mb-1">
                    <!-- Field: Date -->
                    <b-col
                      cols="12"
                      md="4"
                    >
                      <b-form-group
                        :label="$t('Date')"
                        label-for="receipt-date"
                      >
                        <b-input-group class="input-group-merge">
                          <b-input-group-prepend is-text>
                            <feather-icon icon="CalendarIcon" />
                          </b-input-group-prepend>
                          <flat-pickr
                            id="receipt-date"
                            v-model="receiptDate"
                            class="form-control invoice-edit-input"
                            :config="{ dateFormat: 'Y-m-d'}"
                            trim
                            placeholder=""
                          />
                        </b-input-group>
                      </b-form-group>
                    </b-col>

                    <!-- Field: Reference -->
                    <b-col
                      cols="12"
                      md="4"
                    >
                      <b-form-group
                        :label="$t('Reference')"
                        label-for="receipt-reference"
                      >
                        <b-form-input
                          id="receipt-reference"
                          v-model="receiptReference"
                          trim
                          placeholder=""
                        />
                      </b-form-group>
                    </b-col>

                    <!-- Field: Amount -->
                    <b-col
                      md="4"
                    >
                      <b-form-group
                        :label="$t('Amount')"
                        label-for="receipt-amount"
                      >
                        <b-input-group>
                          <b-form-input
                            id="receipt-amount"
                            v-model="receiptAmount"
                            type="number"
                            trim
                            placeholder=""
                          />
                          <b-input-group-append>
                            <b-button
                              variant="outline-primary"
                              @click="addNewReceipt"
                            >
                              <feather-icon
                                icon="PlusIcon"
                                class="mr-50"
                              />{{ $t('Add') }}
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>

                      </b-form-group>

                    </b-col>
                  </b-row>

                  <div
                    ref="formreceipt"
                    class="repeater-form"
                    :style="{height: trHeightReceipt}"
                  >
                    <b-row
                      v-for="(receipt, index) in saleData.sale_receipts"
                      :key="index"
                      ref="rowreceipt"
                      class="pb-2"
                    >

                      <!-- Receipt Form -->
                      <!-- ? This will be in loop => So consider below markup for single receipt -->
                      <b-col cols="12">

                        <!-- Form Input Fields OR content inside bordered area  -->
                        <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                        <div class="d-flex border rounded">

                          <b-row class="flex-grow-1 p-1">

                            <!-- Date -->
                            <b-col
                              cols="6"
                              lg="3"
                              sm="4"
                            >
                              <label class="d-inline">{{ $t('Date') }}</label>
                              <b-form-input
                                v-model="receipt.receipt_date"
                                type="text"
                                class="mb-1 plain"
                                readonly
                              />
                            </b-col>

                            <!-- Reference -->
                            <b-col
                              cols="6"
                              lg="4"
                              sm="4"
                            >
                              <label class="d-inline">{{ $t('Reference') }}</label>
                              <b-form-input
                                v-model="receipt.reference"
                                type="text"
                                class="mb-1"
                              />
                            </b-col>

                            <!-- Amount -->
                            <b-col
                              cols="12"
                              lg="4"
                              sm="4"
                            >
                              <label class="d-inline">{{ $t('Amount') }}</label>
                              <b-form-input
                                v-model="receipt.amount"
                                type="number"
                                class="mb-1"
                                @input="calTotalReceipt()"
                              />
                            </b-col>
                          </b-row>

                          <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                            <feather-icon
                              size="16"
                              icon="XIcon"
                              class="cursor-pointer"
                              @click="removeReceipt(index)"
                            />

                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </div>

                </section>

              </section>

            </b-tab>

          </b-tabs>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <b-row>

            <!-- Field: Reference -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('Reference')"
                label-for="reference"
              >
                <b-form-textarea
                  id="reference"
                  v-model="saleData.reference"
                  trim
                  placeholder=""
                />
              </b-form-group>
            </b-col>

            <!-- Field: Remark -->
            <b-col
              cols="12"
              md="8"
            >
              <b-form-group
                :label="$t('Remark')"
                label-for="remark"
              >
                <b-form-textarea
                  id="remark"
                  v-model="saleData.remark"
                  trim
                  placeholder=""
                />
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="button"
              @click="onSubmit"
            >
              <feather-icon icon="SaveIcon" />&nbsp;
              {{ $t('Save') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="cancel"
            >
              <feather-icon icon="XIcon" />&nbsp;
              {{ $t('Cancel') }}

            </b-button>
          </div>

        </b-form>

      </validation-observer>

    </b-card>
  </b-overlay>
</template>

<script>

import {
  BOverlay, BRow, BCol, BCard, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BInputGroup, BInputGroupPrepend, BInputGroupAppend,
  BTabs, BTab, BFormTextarea,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, onUnmounted } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from '@/libs/vue-select'
import flatPickr from 'vue-flatpickr-component'
import OfflineDB from '@/libs/offline-db'
import store from '@/store'
import router from '@/router/index'
import storeModule from '../../../common/storeModule'
import saleStoreModule from '../saleStoreModule'

export default {
  components: {
    BOverlay,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BRow,
    BCol,
    flatPickr,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BTabs,
    BTab,
    BFormTextarea,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      trHeightReceipt: null,
      required,
      itemOptions: [],
      clientOptions: [],
      saleRegionOptions: [],
      itemId: null,
      itemQuantity: null,
      receiptDate: this.today,
      receiptReference: null,
      receiptAmount: null,
      offlineDB: new OfflineDB(),
      isDisabled: true,
      statusOptions: [
      // 0 = new, 1 = unpaid, 2 = partially paid, 3 = paid, 4 = cancelled
        { label: this.$t('Unpaid'), value: '1' },
        { label: this.$t('Partially Paid'), value: '2' },
        { label: this.$t('Paid'), value: '3' },
      ],
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    'saleData.total_amount': function () {
      this.payButtonControl()
    },
    // eslint-disable-next-line func-names
    'saleData.sale_receipts': function () {
      this.payButtonControl()
    },
  },
  mounted() {
    this.initTrHeight()
    this.initTrHeightReceipt()
    this.loadClients()
    this.loadItems()
    this.loadSaleRegions()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
    window.addEventListener('resize', this.initTrHeightReceipt)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
    window.removeEventListener('resize', this.initTrHeightReceipt)
  },
  methods: {
    trSetHeightReceipt(val) {
      // Set height
      // Usage: Mostly for assigning initial value from mounted hook

      /* Assumes:
       - Height is not assigned and what to assign for add/remove operation
       - What to set height at something for odd usage
       - Incoming value is valid number in `Number` or `String`
      */
      if (val === null) this.trHeightReceipt = 'auto'
      else this.trHeightReceipt = `${Number(val)}px`
    },
    quantityEnter() {
      this.addNewItem()
      document.getElementById('item').focus()
      // this.$refs.item.$el.focus()
    },
    loadClients() {
      store
        .dispatch('common/fetchClients', { id_name: 1 })
        .then(response => {
          const { clients } = response.data.data
          for (let i = 0; i < clients.length; i += 1) {
            this.clientOptions.push({ label: clients[i].name, value: clients[i].id })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    loadItems() {
      store
        .dispatch('common/fetchItems', { id_name: 1, sale: 1 })
        .then(response => {
          const { items } = response.data.data
          this.offlineDB.items.clear()
          this.offlineDB.items.bulkAdd(items)
          // const someFriends = await this.offlineDB.items.where({ id: 2 }).first()
          // console.log(someFriends)
          for (let i = 0; i < items.length; i += 1) {
            this.itemOptions.push({ label: items[i].name, value: items[i].id })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    loadSaleRegions() {
      store
        .dispatch('common/fetchSaleRegions', { id_name: 1 })
        .then(response => {
          const { sale_regions } = response.data.data
          for (let i = 0; i < sale_regions.length; i += 1) {
            this.saleRegionOptions.push({ label: sale_regions[i].name, value: sale_regions[i].id })
          }
          this.saleData.sale_region_id = JSON.parse(localStorage.staffData).sale_region_id
        })
        .catch(error => {
          console.log(error)
        })
    },
    cancel() {
      this.$router.push({ name: 'sale-list' })
    },
    async addNewItem() {
      if (this.itemId !== null && this.itemQuantity !== null) {
        this.$refs.formitem.style.overflow = 'hidden'
        const selectedItem = await this.offlineDB.items.where({ id: this.itemId }).first()

        if (this.saleData.sale_items.some(obj => obj.item_id === this.itemId)) {
          this.$swal('Warning', 'Item already exists', 'warning')
          return
        }

        const sqty = Number(this.itemQuantity)
        let sprice = Number(selectedItem.sale_price)
        const { pricing } = selectedItem
        if (pricing.length !== 0) {
          for (let i = 0; i < pricing.length; i += 1) {
            const isBetweenMinMax = (sqty >= pricing[i].minimum_quantity && sqty <= pricing[i].maximum_quantity)
            const isGreaterThanMin = (sqty >= pricing[i].minimum_quantity)
            if (isBetweenMinMax || isGreaterThanMin) {
              sprice = pricing[i].price
            }
          }
        }

        const selectedName = selectedItem.name.split(' - ')
        const itemName = `${selectedName[0]} ${selectedName[1]}`

        this.itemFormBlankItem.item_name = itemName
        this.itemFormBlankItem.item_id = selectedItem.id
        this.itemFormBlankItem.price = sprice
        this.itemFormBlankItem.quantity = sqty
        this.itemFormBlankItem.amount = sprice * sqty

        this.saleData.sale_items.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)))

        this.itemId = null
        this.itemQuantity = null

        this.calTotalAmount()

        this.$nextTick(() => {
          this.trAddHeight(this.$refs.rowitem[0].offsetHeight)
          setTimeout(() => {
            this.$refs.formitem.style.overflow = null
          }, 350)
        })
      }
    },
    addNewReceipt() {
      if (this.receiptDate !== null && this.receiptAmount !== null) {
        this.$refs.formreceipt.style.overflow = 'hidden'

        if (this.saleData.sale_receipts.some(obj => obj.receipt_date === this.receiptDate)) {
          this.$swal('Warning', 'Receipt already exists on that date', 'warning')
          return
        }

        this.receiptFromBlankReceipt.receipt_date = this.receiptDate
        this.receiptFromBlankReceipt.reference = this.receiptReference
        this.receiptFromBlankReceipt.amount = this.receiptAmount

        this.saleData.sale_receipts.push(JSON.parse(JSON.stringify(this.receiptFromBlankReceipt)))

        this.receiptDate = this.today
        this.receiptReference = null
        this.receiptAmount = null

        this.calTotalReceipt()

        this.$nextTick(() => {
          this.trAddHeight(this.$refs.rowreceipt[0].offsetHeight)
          setTimeout(() => {
            this.$refs.formreceipt.style.overflow = null
          }, 350)
        })
      }
    },
    removeItem(index) {
      this.saleData.sale_items.splice(index, 1)
      this.calTotalAmount()
      this.trTrimHeight(this.$refs.rowitem[0].offsetHeight)
    },
    removeReceipt(index) {
      this.saleData.sale_receipts.splice(index, 1)
      this.calTotalReceipt()
      this.trTrimHeight(this.$refs.rowreceipt[0].offsetHeight)
    },
    calItemAmount(itemId, price, quantity) {
      const index = this.saleData.sale_items.findIndex((obj => obj.item_id === itemId))
      const amount = Number(price) * Number(quantity)
      this.saleData.sale_items[index].amount = amount
      this.calTotalAmount()
    },
    calTotalAmount() {
      this.saleData.total_amount = this.saleData.sale_items.reduce((a, b) => +a + +b.amount, 0)
      this.saleData.net_amount = this.saleData.total_amount - this.saleData.discount
      this.setStatus()
    },
    calTotalReceipt() {
      this.saleData.total_receipt = this.saleData.sale_receipts.reduce((a, b) => +a + +b.amount, 0)
      this.setStatus()
    },
    setStatus() {
      // 0 = new, 1 = unpaid, 2 = partially paid, 3 = paid, 4 = cancelled
      if (this.saleData.total_receipt > 0) {
        if (this.saleData.total_receipt === this.saleData.net_amount) {
          this.saleData.status = '3'
        }
        if (this.saleData.total_receipt < this.saleData.net_amount) {
          this.saleData.status = '2'
        }
      } else if (this.saleData.total_receipt === 0) {
        this.saleData.status = '1'
      }
    },
    payNow() {
      this.receiptFromBlankReceipt.receipt_date = this.today
      this.receiptFromBlankReceipt.reference = 'Cash Pay'
      this.receiptFromBlankReceipt.amount = this.saleData.net_amount
      this.saleData.sale_receipts = []
      this.saleData.sale_receipts.push(JSON.parse(JSON.stringify(this.receiptFromBlankReceipt)))

      this.receiptDate = this.today
      this.receiptReference = null
      this.receiptAmount = null

      this.calTotalReceipt()

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.rowreceipt[0].offsetHeight)
        setTimeout(() => {
          this.$refs.formreceipt.style.overflow = null
        }, 350)
      })
    },
    payButtonControl() {
      if (this.saleData.net_amount !== null) {
        if (this.saleData.net_amount > 0) {
          if (this.saleData.total_receipt === this.saleData.net_amount) {
            this.isDisabled = true
          } else {
            this.isDisabled = false
          }
        } else {
          this.isDisabled = true
        }
      } else {
        this.isDisabled = true
      }
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.formitem.scrollHeight)
      })
    },
    initTrHeightReceipt() {
      this.trSetHeightReceipt(null)
      this.$nextTick(() => {
        this.trSetHeightReceipt(this.$refs.formreceipt.scrollHeight)
      })
    },
  },
  setup() {
    const STORE_MODULE_NAME = 'sale'

    // Register module
    if (!store.hasModule('common')) store.registerModule('common', storeModule)
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, saleStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    /** request data format
    {
      "sale_date" : "2021-03-21",
      "client_id" : 3,
      "total_amount" : 1000,
      "total_receipt" : 1000,
      "discount" : 0,
      "reference" : "457",
      "remark" : "none",
      "sale_region_id" : 1,
      "status" : 3,
      "sale_items" : [
          {
            "item_id" : 2,
            "quantity" : 1,
            "price" : 500,
            "amount" : 500
          },
      ],
      "sale_receipts" : [
          {
              "receipt_date" : "2021-03-21",
              "amount" : 1000,
              "reference" : "cash"
          }
      ]
    }
    */

    const itemFormBlankItem = {
      item_name: '',
      item_id: null,
      price: 0,
      quantity: 0,
      amount: 0,
    }

    const current = new Date()
    const month = (current.getMonth() + 1) < 10 ? `0${(current.getMonth() + 1)}` : (current.getMonth() + 1)
    const date = (current.getDate()) < 10 ? `0${(current.getDate())}` : (current.getDate())
    const today = `${current.getFullYear()}-${month}-${date}`

    // const minDate = current // minimum date that user can choose in new sale invoice
    const maxDate = (new Date(current.getFullYear(), current.getMonth() + 1, 0)) // maximum date that user can choose in new sale invoice

    const saleData = ref({
      sale_date: today,
      client_id: null,
      total_amount: 0,
      discount: 0,
      net_amount: 0,
      total_receipt: 0,
      staff_name: '',
      reference: '',
      remark: '',
      branch_name: '',
      status: null,
      sale_region_id: null,
      sale_items: [],
      sale_receipts: [],
    })

    const receiptFromBlankReceipt = {
      receipt_date: today,
      reference: '',
      amount: 0,
    }

    const onSubmit = () => {
      const data = JSON.parse(JSON.stringify(saleData.value))
      store.dispatch('sale/add', data)
        .then(response => {
          if (response.status === 201) {
            router.push({ name: 'sale-view', params: { id: response.data.data.id } })
          } else {
            console.log(response)
          }
        })
        .catch(error => {
          window.swal('Error', JSON.stringify(error.response.data).replace(/"([^"]+)":/g, '$1:'))
        })
    }

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      saleData,
      itemFormBlankItem,
      receiptFromBlankReceipt,
      today,
      // minDate,
      maxDate,
      onSubmit,

      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style>
[dir] .vs--disabled .vs__dropdown-toggle, [dir] .vs--disabled .vs__clear, [dir] .vs--disabled .vs__search, [dir] .vs--disabled .vs__selected, [dir] .vs--disabled .vs__open-indicator {
  background-color: #ffffff;
}

</style>
